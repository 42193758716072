.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  color: rgb(0, 0, 0);
  background-color: rgb(255, 235, 199);
}

.heading {
  font-size: 50px;
  margin: 40px 40px 0px 40px;
}

.subtitle {
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 15px;
}

.croncert-logo-container {
  margin: 20px 0 10px 0;
}
