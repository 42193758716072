.searchbar__box {
  display: flex;
  max-width: 800px;
  height: 20px;
  width: 90%;
  position: relative;
  background: rgb(236, 246, 192);
  border: 1px solid black;
  padding: 10px 0 10px 0;
}

::placeholder {
  color: rgb(146, 153, 121);
  opacity: 1;
}

.searchbar__title {
  width: 60%;
  padding-left: 20px;
}

.searchbar_input_title {
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-family: inherit;
  font-weight: 300;
  font-size: 16px;
  float: left;
  width: 100%;
  height: 20px;
  color: rgb(0, 0, 0);
}

.searchbar_input_title:focus {
  outline: none;
}

.searchbar_input_city:focus {
  outline: none;
}

.searchbar__city {
  width: 40%;
  padding-left: 10px;
  border-left: 1px solid rgb(0, 0, 0);
}

.searchbar_input_city {
  width: 80%;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-family: inherit;
  font-weight: 300;
  font-size: 16px;
  float: left;
  height: 20px;
  color: rgb(0, 0, 0);
}

.concertlist__box {
  max-width: 800px;
  min-height: 1110px;
  width: 90%;
  position: relative;
  background: rgb(219, 218, 255);
  border: 1px solid black;
  margin-top: 20px;
  padding: 10px 0 10px 0;
  display: flex;
  flex-direction: column;
}

.concertitem__box {
  padding: 10px 0 10px 0;
  margin: 0 20px 0 20px;
  font-size: 30px;
}

.concertitem__field {
  position: relative;
  text-align: left;
}

.concertitem__title {
  font-size: 20px;
  font-weight: 200;
  padding: 5px 5px 2px 5px;
}

.concertitem__title a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: 0.2s;
}

.concertitem__title a:hover {
  color: rgb(198, 136, 20);
}

.concertitem__genres {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px 0px 5px;
}

.concertitem__genre {
  font-size: 12px;
  margin: 2px;
  padding: 2px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-weight: 500;
  border-radius: 4px;
  white-space: nowrap;
}

.concertitem__location {
  font-size: 16px;
  /* font-weight: 400; */
  padding: 2px 0 2px 5px;
}

.concertitem__location a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: 0.2s;
  /* font-weight: 500; */
}

.concertitem__location a:hover {
  color: rgb(198, 136, 20);
}

.concertitem__date {
  font-size: 24px;
  padding: 5px 0px 2px 5px;
}

.pagination {
  margin-top: auto;
  font-size: 16px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 205, 212);
  border: 1px solid black;
  margin-top: 20px;
}

.pagination ul {
  list-style: none;
  padding: 0;
  margin: 10px 10px 6px 10px;
}

.pagination ul li {
  display: inline-block;
  line-height: 35px;
}

.pagination ul li a {
  display: block;
  width: 40px;
  border: 1px solid rgb(255, 205, 212);
}

.pagination ul li a:hover {
  border: 1px solid rgb(0, 0, 0);
  width: 40px;
  height: 30px;
  cursor: pointer;
}

.pagination ul li.selected a {
  border: 1px solid rgb(0, 0, 0);
  width: 40px;
  height: 30px;
  background: rgb(230, 167, 176);
}

.noconcerts {
  padding: 20px;
}

.noconcerts a {
  text-decoration: none;
  color: rgb(198, 136, 20);
  font-weight: 300;
}

.noconcerts a:hover {
  color: rgb(0, 0, 0);
  transition: 0.2s;
}

.noconcerts button {
  text-decoration: none;
  color: rgb(198, 136, 20);
  font-weight: 300;
  font-family: inherit;
  font-size: 16px;
  border: none;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
}

.noconcerts button:hover {
  color: rgb(0, 0, 0);
  transition: 0.2s;
  cursor: pointer;
}

.footer__box {
  padding: 20px;
  display: flex;
}

.contribute__box {
  padding: 10px;
}

.contribute__box a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-weight: 400;
  transition: 0.2s;
}

.contribute__box a:hover {
  color: rgb(198, 136, 20);
}

.filter-button__container {
  margin: 0;
  padding-right: 12px;
}

.filter-button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  padding: 0;
}

.filter-button:hover {
  cursor: pointer;
}

.filter-wrapper__container {
  max-width: 802px;
  width: 90%;
  position: fixed;
  margin-top: 205px;
  z-index: 10;
}

.filter__container {
  background: rgb(236, 246, 192);
  border: 1px solid black;
  float: right;
}

.filter__item {
  width: 230px;
  height: 22px;
  margin: 20px;
  display: flex;
  align-items: center;
}

.filter__item > img {
  margin-right: 2px;
}

.filter__container img {
  float: right;
}

.filter__container input {
  float: left;
  cursor: pointer;
  appearance: none;
  width: 120px;
  height: 3px;
  background: black;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.filter__container span {
  width: 80px;
  margin-left: 5px;
  color: rgb(0, 0, 0);
}

.filter__container input:hover {
  opacity: .7;
}

.filter__container input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px black solid;
  background: rgb(193, 205, 141);
  cursor: pointer;
}

.filter__container .filter__item {
  color: black;
  font-size: 16px;
}

.datepicker-date__container {
  all: unset;
  cursor: pointer;
  border: none;
  background: rgb(0, 0, 0, 0);
  width: 100%;
  height: 22px;
  margin-left: 3px;
  display: flex;
  align-items: center;
}

.filter__container>button {
  all: unset;
  border: 2px solid rgb(0, 0, 0);
  background: rgb(0, 0, 0, 0);
  font-size: 16px;
  margin: 10px;
  padding: 5px;
  color: black;
  font-weight: 500;
}

.datepicker-remove-date {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-size: 16px;
  font-family: inherit;
  color: black;
}

.datepicker-button__container {
  margin: 0;
  padding-right: 12px;
}

.datepicker-button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  padding: 0;
  margin-left: 5px;
}

.datepicker-button:hover {
  cursor: pointer;
}

.datepicker__container {
  background: rgb(236, 246, 192);
  border: 1px solid black;
  float: right;
}

.datepicker-wrapper__container {
  max-width: 802px;
  width: 90%;
  position: absolute;
  margin-top: 310px;
  z-index: 10;
}

.react-datepicker {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
}

.react-datepicker__month-container {
  font-size: 18px;
}

.react-datepicker__header {
  margin-top: 5px;
  position: relative;
}

.react-datepicker__day-names {
  margin-top: 8px;
  font-weight: 400;
  display: flex;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day {
  width: 25px;
  height: 18px;
  padding: 7px;
  border: 1px solid rgb(236, 246, 192);
}

.react-datepicker__day:hover {
  padding: 7px;
  border: 1px solid rgb(0, 0, 0);
  cursor: pointer;
}

.react-datepicker__day--selected {
  padding: 7px;
  border: 1px solid rgb(0, 0, 0);
  background: rgb(193, 205, 141);
}

.react-datepicker__day-name {
  width: 25px;
  height: 18px;
  padding: 8px;
}

.react-datepicker__month {
  margin-top: 2px;
}

.react-datepicker__navigation {
  z-index: 11;
  display: flex;
  position: absolute;
  text-indent: -9999px;
  height: 20px;
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  border: none;
  cursor: pointer;
  padding: 0;
}

.react-datepicker__navigation--previous {
  top: 13px;
  left: 15px;
  background-image: url("arrow-48.png");
  background-size: 20px;
  transform: rotate(-90deg);
  background-color: rgba(255, 255, 255, 0);
}

.react-datepicker__navigation--next {
  top: 13px;
  right: 15px;
  background-image: url("arrow-48.png");
  background-size: 20px;
  transform: rotate(90deg);
  background-color: rgba(255, 255, 255, 0);
}

.react-datepicker__day--outside-month {
  color: rgb(176, 176, 176);
}

p {
  text-align: left;
  max-width: 600px;
  padding: 0 20px 0 20px;
  line-height: 20px;
}

p a {
  text-decoration: none;
  color: rgb(198, 136, 20);
}

p a:visited {
  text-decoration: none;
}

p a:hover {
  color: rgb(0, 0, 0);
  transition: 0.2s;
}

.suggestions-wrapper__container {
  max-width: 800px;
  width: 96%;
  position: absolute;
  margin-top: 41px;
  z-index: 11;
}

.suggestions-wrapper {
  background: rgb(236, 246, 192);
  border: 1px solid black;
  float: right;
  width: 40%;
  min-width: 140px;
  text-align: left;
}

.suggestions {
  position: relative;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
  list-style: none;
  text-align: left;
}

.suggestion ul {
  width: 100%
}

.suggestions li {
  padding: 0.5rem;
  border: 1px solid transparent;
}

.suggestions .suggestion-active,
.suggestions li:hover {
  cursor: pointer;
  font-weight: 400;
  border: 1px solid rgb(0, 0, 0);
}

.hidden-city-suggestions {
  display: none;
}

.loading {
  margin: 40px;
}

.notifications-wrapper__container {
  max-width: 300px;
  width: 90%;
  position: absolute;
  margin-top: 320px;
  z-index: 10;
}

.notifications__container {
  background: rgb(236, 246, 192);
  border: 1px solid black;
  padding: 10px 20px 10px 20px;
}

.noti_form {
  padding: 12px 0 5px 0;
  border: none;
  width: 100%;
  float: left;
}

.noti_form:not(:nth-last-child(3)) {
  border-bottom: 1px solid rgb(0, 0, 0);
}

.noti-error-message {
  padding: 20px 0 0 0;
  border: none;
  width: 100%;
  float: left;
  font-family: inherit;
  font-weight: 500;
  color: rgb(208, 0, 0);
  height: 20px;
}

.noti-success {
  padding: 40px 0 10px 0;
  width: 100%;
}

.noti_input {
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-family: inherit;
  font-weight: 200;
  font-size: 16px;
  width: 100%;
  height: 20px;
  color: rgb(0, 0, 0);
}

.noti_input:focus {
  outline: none;
}

.noti_form_city {
  padding: 10px 0 10px 0;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-family: inherit;
  width: 90%;
  float: left;
  height: 20px;
}
.noti_form img {
  float: right;
  margin-left: 2px;
  margin-right: 10px;
}

.noti_form .range-slider {
  float: left;
  cursor: pointer;
  appearance: none;
  width: 120px;
  height: 3px;
  background: black;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 2px;
  margin: 10px;
}

.noti_form .range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px black solid;
  background: rgb(193, 205, 141);
  cursor: pointer;
}

.noti_form span {
  width: 60px;
  margin-left: 5px;
}

.noti-button__container {
  padding-right: 12px;
}

.noti-button {
  margin: 10px;
  background-color: rgba(255, 255, 255, 0);
  border: 1px black solid;
  padding: 10px;
  font-family: inherit;
}

.noti-button:hover:enabled {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.noti-button:disabled {
  cursor: default;
  border: 1px rgb(78, 78, 78) solid;
}

.close-noti-container {
  float: right;
}

.close-noti-container button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-family: inherit;
  font-size: 16px;
}

.close-noti-container button:hover {
  cursor: pointer;
}

.contribute__header {
  padding: 10px 0 10px 0;
  height: 20px;
  width: 100%;
  background-color: rgb(255, 102, 0);
  text-align: center;
  flex-direction: column;
}

.contribute__close {
  margin-top: -18px;
  font-size: 16px;
  position: absolute;
  width: 100%;
}

.contribute__close button {
  z-index: 20;
  position: relative;
  margin-right: 5px;
  float: right;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-family: inherit;
  font-size: 16px;
}

.contribute__close button:hover {
  cursor: pointer;
}

.contribute__link {
  z-index: 10;
  position: relative;
  /* float: left; */
}

/* .contribute__link a {
  float: right;
} */
 .contribute__box {
  padding: 10px;
}

.contribute__header a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-weight: 400;
  transition: 0.2s;
}

.contribute__link a:hover {
  color: rgb(198, 136, 20);
}